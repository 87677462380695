<template>

  <div class="row" ref="mediaForm">

    <div class="col-xs-12 col-sm-12 col-md-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>

          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <ValidationProvider
                  vid="primary_title"
                  rules="required"
                  name="The Primary Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Primary Title"
                            name="primary_title"
                            fou
                            @keyup="generateMetaTitleURL"
                            v-model="formData.primary_title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="secondary_title"
                  rules="required"
                  name="the Secondary Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Secondary Title"
                            name="secondary_title"
                            fou
                            @keyup="generateMetaTitleURL"
                            v-model="formData.secondary_title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="url"
                  rules="required"
                  name="The url"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Url"
                            name="url"
                            fou
                            v-model="formData.url">
                  </fg-input>
                </ValidationProvider>

                <div class="form-group">
                  <label>Featured Media</label>&nbsp;
                  <l-switch v-model="formData.is_featured">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
                <fg-select
                  name="type"
                  size="large"
                  multiple
                  filterable
                  placeholder="Type"
                  :input-classes="'select-info'"
                  :label="'Types'"
                  :list="typeOptions"
                  :listItemLabel="'title'"
                  :listItemValue="'id'"
                  v-model="formData.type">
                </fg-select>
               <ValidationProvider
                  vid="meta_title"
                  rules="required"
                  name="The Meta Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Meta Title"
                            name="meta_title"
                            fou
                            v-model="formData.meta_title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="meta_description"
                  rules=""
                  name="The Meta Description"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Meta Description"
                            name="meta_description"
                            fou
                            v-model="formData.meta_description">
                  </fg-input>
                </ValidationProvider>

                <div class="form-group">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <h4 class="card-title">Media Video
              </h4>
              <div class="col-12">
                <div class="">
                  <div>
                    <div class="col-12">
                      <div class="form-group row mt-4">
                        <div class="col-md-12">
                          <div class="custom-file">
                            <ValidationProvider
                                    vid="url"
                                    rules=""
                                    name="Video Embed "
                                    v-slot="{ passed, failed,errors }">
                              <fg-input type="text"
                                        :error="failed ? errors[0]: null"
                                        label="Video Embed"
                                        name="video"
                                        fou
                                        v-model="formData.video">
                              </fg-input>
                            </ValidationProvider>
                            <div v-html="formData.video">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <label>Description</label>
                <editor

                  v-model="formData.description"
                  :api-key="editorKey"
                  :init='{
                    height: 300,
                    paste_data_images: true,
                    plugins: [
                    "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                     "searchreplace wordcount visualblocks visualchars code fullscreen",
                    "insertdatetime media nonbreaking save table contextmenu directionality",
                    "emoticons template paste textcolor colorpicker textpattern"
                    ],
                    toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    toolbar2: "print preview media | forecolor backcolor emoticons",
                    image_advtab: true,
                  }'
                />
              </div>

            </div>

            <div class="row mt-4">
              <h4 class="card-title">Media Images
              </h4>
              <div class="col-12">
                <div class="row">
                  <template v-for="(image,imageIndex) in formData.images">
                    <div class="col-3">
                      <div class="col-12">
                        <label>Image:</label>
                        <el-tooltip placement="right" v-if="getBannerImageInfo()">
                          <div slot="content">{{getBannerImageInfo()}}</div>
                          <span style="margin: 0 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                          </svg>
                          </span>
                        </el-tooltip>

                        <image-uploader
                          :preview-width="'200px'"
                          :preview-height="'200px'"
                          v-model="image.image"></image-uploader>
                      </div>
                      <div class="col-12" style="display: flex ; justify-content: flex-start;align-items: center">
                        <fg-input type="number"
                                  label="order"
                                  style="max-width: 50%;"
                                  fou
                                  v-model="image.sort_order">
                        </fg-input>
                        <div class="ml-4" v-if="formData.images && formData.images.length > 1">
                          <l-button
                            @click="removeImage(imageIndex)"
                            type="danger" size="sm">
                                <span class="btn-label">
                                   <i class="fa fa-trash"></i>
                                </span>
                          </l-button>
                        </div>
                      </div>
                    </div>

                  </template>
                </div>
              </div>
              <div class="col-12">
                <l-button
                  class="mt-2"
                  type="success"
                  @click="addNewImage()"
                  size="sm">
                       <span class="btn-label">
                         add new image
                         <i class="fa fa-plus"></i>
                         </span>
                </l-button>
              </div>


            </div>

            <div class="row mt-4">
              <h4 class="card-title">Media PDF
              </h4>
              <div class="col-12">
                <div class="d-flex justify-content-between">
                  <div v-if="!old_pdf">
                    <div class="col-12">
                        <div class="form-group row mt-4">
                          <div class="col-md-6">
                            <div class="custom-file">
                              <input accept=".pdf" type="file" class="custom-file-input" id="customFile" ref="file" @change="handleFileObject()">
                              <label class="custom-file-label text-left" for="customFile"></label>
                            </div>
                          </div>
                          <label class="col-md-4 col-form-label text-md-right">{{ avatarName }}</label>
                        </div>
                    </div>
                  </div>
                  <div v-if="old_pdf">
                    <div>
                      <div class="row">
                        <div class="col-6">
                        <div class="d-flex justify-content-start">
                          <a :href="urlForPdf + formData.pdf" target="_blank">
                            <div><i class="nc-icon nc-notes pdf-link" style="font-size:45px"></i></div>
                          </a>
                          <div>
                              <span class="btn-label">
                                <i class="fa fa-trash" style="color:red;cursor: pointer;" @click="removePdf()"></i>
                              </span>
                          </div>

                        </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>

            <l-button @click="$router.push('/media/list')" type="danger" wide>Cancel</l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import {FormGroupSelect, ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import { Tooltip} from 'element-ui';


extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {
    FgSelect,
    LSwitch,
    [Tooltip.name]: Tooltip,
    FormGroupSelect,
    ImageUploader,
    'editor': Editor
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })

  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      urlForPdf:process.env.VUE_APP_SERVER_IMAGE_URL,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      editorKey:process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        primary_title: "",
        secondary_title: "",
        description: "",
        url: "",
        meta_title: "",
        meta_description: "",
        is_active: false,
        is_featured: false,
        sort_order: null,
        images: [],
        type:[],
        video: ""
      },
      avatar: null,
      avatarName: null,
      old_pdf:null,
        typeOptions:[
            {id:"news",label:"news"},
            {id:"video",label:"video"},
            {id:"gallery",label:"gallery"},
            {id:"article",label:"article"},
        ],
    };
  },


  mounted() {
     this.loader = this.$loading.show({
      container: this.$refs.mediaForm
    });

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;

      this.formTitle = "Edit Media";
    } else {
      this.editMode = false;
      this.formTitle = "Add Media";
    }
    this.prepareBuilders();
  },

  methods: {

    handleFileObject() {
      this.avatar = this.$refs.file.files[0];
      console.log(this.avatar);
      this.avatarName = this.avatar.name;
      console.log(this.avatarName);
    },

    async getMedia() {
      if (!this.editMode) {
        this.formData.images.push({"id": null, "image": "", sort_order: 1});
        return;
      }
      try {
        let response = await this.axios.get("media/get/" + this.id);
        this.formData = response.data;
        this.old_pdf= response.data.pdf;
        console.log(this.formData);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Media Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
      }
    },
    async prepareBuilders() {
      await this.getMedia();
      this.loader.hide();
    },
    getEmptyIfNull(value){
        if(value){
          return value ;
        }
        return '';
    },
    async submit() {
      let request;
      let successMessage;
      this.submitting = true;
      let imageValidation = false;
      let typeValidation = false;
      let formDataForFile = new FormData();
      formDataForFile.append('primary_title',this.getEmptyIfNull(this.formData.primary_title));
      formDataForFile.append('secondary_title',this.getEmptyIfNull(this.formData.secondary_title));
      formDataForFile.append('description',this.getEmptyIfNull(this.formData.description));
      formDataForFile.append('url',this.getEmptyIfNull(this.formData.url));
      formDataForFile.append('meta_title',this.getEmptyIfNull(this.formData.meta_title));
      formDataForFile.append('meta_description',this.getEmptyIfNull(this.formData.meta_description));
      let favorit = this.formData.is_featured ? 1: 0 ;

      let active = this.formData.is_active ? 1: 0 ;

      formDataForFile.append('is_featured',favorit);
      formDataForFile.append('is_active',active);
      formDataForFile.append('sort_order',this.formData.sort_order);
      formDataForFile.append('images',JSON.stringify(this.formData.images));
      formDataForFile.append('pdf',this.getEmptyIfNull(this.avatar));
      formDataForFile.append('old_pdf',this.getEmptyIfNull(this.old_pdf));
      formDataForFile.append('type',this.getEmptyIfNull(this.formData.type));
      formDataForFile.append('video',this.getEmptyIfNull(this.formData.video));

      for (let image of this.formData.images) {
        if (!image.image) {
          imageValidation = true;
        }
      }
      if (imageValidation && !this.formData.type.includes('video')) {
        this.$notify({
          message: "You Must Upload Image",
          timeout: 2000,
          type: 'danger'
        });
        this.submitting = false;
        return;
      }
      if (this.formData.type && !this.formData.type.length){
          typeValidation = true;
      }
      console.log(typeValidation);
        if (typeValidation) {
            this.$notify({
                message: "You Must Add at least one type option",
                timeout: 2000,
                type: 'danger'
            });
            this.submitting = false;
            return;
        }
      if (this.editMode === true) {

        request = this.axios.post("media/update/" + this.id, formDataForFile);
        successMessage = "Media Updated Successfully";
      } else {
        request = this.axios.post("media/create", formDataForFile);
        successMessage = "Media Added Successfully";
      }

      try {
        await request;
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/media/list");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      } finally {
        this.submitting = false;
      }
    },

    generateMetaTitleURL() {

      let newUrl = '';
      if (this.formData.primary_title) {
        newUrl = this.formData.primary_title.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
      // meta title
      this.formData.meta_title = this.formData.primary_title;
    },

    addNewImage() {
      let order = this.formData.images.length + 1;
      this.formData.images.push({"id": null, "image": "", sort_order: order});
    },
    removeImage(index) {
      this.formData.images.splice(index, 1);
    },
    removePdf() {
      this.old_pdf = null;
    },
    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo['banner_image_media']) {
        return this.mediaInfo["banner_image_media"];
      } else {
        return "";
      }
    }

  }
}
</script>

<style>
</style>
