<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Media List</h4>
          <router-link v-if="$store.getters['auth/checkAccess']('media/create')"
                       class="btn btn-info btn-wd"
                       :to="'/media/create'">
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>
        <!--    filters    -->
        <div class="row col-12 mb-2">
          <div class="col-md-6 col-sm-4 filters-select">
            <fg-select
              size="large"
              filterable
              clearable
              placeholder="Select Option"
              :input-classes="'select-default'"
              :list="isFeaturedOptions"
              :listItemLabel="'label'"
              :listItemValue="'value'"
              v-model="filters.is_featured">
            </fg-select>
            <fg-select
                    size="large"
                    filterable
                    clearable
                    placeholder="Select Type"
                    :input-classes="'select-default'"
                    :list="typeOptions"
                    :listItemLabel="'label'"
                    :listItemValue="'value'"
                    v-model="filters.type_options">
            </fg-select>
          </div>

          <div class="col-md-2 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">Filter Result</l-button>
          </div>
        </div>
        <!--  end  filters    -->
        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :row-key="'id'"
            @reorder="reorder($event)"
            :api-url="'media/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

              <template slot-scope="scope">

                <div v-if="column.value === 'image'">
                  <img v-if="scope.row[column.value]" alt="" :src="url +scope.row[column.value]" class="table-image">
                </div>
                <div v-else-if="column.value === 'type'">
                  <!--{{ scope.row[column.value] }}-->
                 <div v-for="option in scope.row[column.value]">
                   {{ option }}
                 </div>
                </div>
                <span v-else>{{ scope.row[column.value] }}</span>


              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/checkAccess']('media/change-active-status')"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>
                <div v-else>
                  <span v-if="props.row.is_active">Active</span>
                  <span v-else>Not Active</span>
                </div>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <router-link v-if="$store.getters['auth/checkAccess']('media/update')"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/media/edit/'+scope.row.id"><i
                  class="fa fa-edit"></i></router-link>

                <a v-if="$store.getters['auth/checkAccess']('media/delete')" v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Media?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";

export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    FgSelect
  },

  data() {

    return {
      tableColumns: [
        {label: 'Primary Title', value: 'primary_title', minWidth: '200', align: 'center'},
        {label: 'Secondary Title', value: 'secondary_title', minWidth: '200', align: 'center'},
        {label: 'Image', value: 'image', minWidth: '200', align: 'center'},
        {label: 'Type Options', value: 'type', minWidth: '200', align: 'center'},
      ],
      sortCreated: false,
      deleteModalVisibility: false,
      toDeleteId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL,
      filters: {
        is_featured: 0,
        type_options: "All"
      },
      isFeaturedOptions :[
        {
          label:'All',
          value:0
        },
        {
          label:'Featured',
          value:1
        }
      ],
      typeOptions: [
          {value:"All",label:"All"},
          {value:"news",label:"news"},
          {value:"video",label:"video"},
          {value:"gallery",label:"gallery"},
          {value:"article",label:"article"},
      ]
    }

  },
  mounted(){

  },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active
      }
      this.axios.post("media/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Media updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      this.axios.delete("media/delete/" + this.toDeleteId).then((response) => {

        this.$notify({
          message: "media deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    async reorder(data) {
      try {
        let body = [];
        data.forEach((row, i) => {
          body.push({"id": row.id, "sort_order": i + 1})
        });

        await this.axios.post("media/re-order", body);

        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },
    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.is_featured === 1) {
        this.sortCreated = true ;
        this.$refs.table.createDragAndDropSorting();
      } else {
        if(this.sortCreated){
          this.sortCreated = false ;
          this.$refs.table.destroyDragAndDropSorting();
        }
      }
      this.$refs.table.getData("updateData");
    },
  },
}
</script>
<style scoped>
  .filters-select{
    display: flex;
    justify-content: space-between;
  }
</style>
